  @font-face {
    font-family: 'QanelasSoftExtraBold';
    src: local('QanelasSoftExtraBold'), url('./fonts/QanelasSoftExtraBold.otf') format('opentype');
  }
  @font-face {
    font-family: 'QanelasSoftMedium';
    src: local('QanelasSoftMedium'), url('./fonts/QanelasSoftMedium.woff') format('woff');
  }
  @font-face {
    font-family: 'QanelasSoftRegular';
    src: local('QanelasSoftRegular'), url('./fonts/QanelasSoftRegular.woff') format('woff');
  }
  @font-face {
    font-family: 'QanelasSoftUltraLight';
    src: local('QanelasSoftUltraLight'), url('./fonts/QanelasSoftUltraLight.otf') format('opentype');
  }

  * {
    margin-top: 0px;
    background-color: #F2E6D8;
    color: rgb(54, 54, 54);
    font-family: QanelasSoftRegular;
  }


  body {
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-family: QanelasSoftRegular;
  }

  h1 {
    font-family: QanelasSoftExtraBold;
    color: #E47C5B;
  }
  
  h2, h3, h4, h5, h6 {
    font-family: QanelasSoftMedium;
    color: #E47C5B;
  }

  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
      monospace;
  }

  /* Container */
  .container {
    margin: 0 auto;
    max-width: 100%;
    width: 1400px;
  }

  .wrapper {
    overflow-y: scroll;
    scroll-snap-type: y mandatory;
  }

  .wrapper div {
    scroll-snap-align: center;
  }

    @media screen and (min-width: 961px) and (max-width: 1880px) {

      .container {
        width: 1200px;
        height: 100%;
      }

      .layer {
        width: 1200px;
        height: 100%;
      }

    }

    @media screen and (min-width: 961px) and (max-width: 1620px) {

      .container {
        width: 960px;
        height: 100%;
      }

      .layer {
        width: 960px;
        height: 100%;
      }

    }

    @media screen and (min-width: 961px) and (max-width: 1320px) {

      .container {
        width: 100%;
        height: 100%;
      }

      .layer {
        width: 100%;
        height: 100%;
      }

    }

    @media screen and (max-width: 960px) {

      .container {
        width: 100%;
        height: 100%;
      }

      .layer {
        width: 100%;
        height: 100%;
      }

    }

    @media screen and (max-width: 736px) {

      .container {
        width: 100%;
        height: 100%;
      }

      .layer {
        width: 100%;
        height: 100%;
      }

    }

  /* Site-Wide Items */

  #breadcrumb-container {
    color: #424242;
    padding: 10px 0px;
    width: 42rem;
  }

  .breadcrumb {
    justify-content: flex-start;
  }

  .breadcrumb-item {
    flex: 1;
  }

  .breadcrumb-item:hover {
    cursor: pointer;
  }

  .image-flex {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
  
  .layer {
    height: 100%;
    width: 100%;
  }

  .section-header {
    margin: 15px;
    padding: 5px;
    width: 10vw;
  }

  .section-content {
    padding: 5px;
    display: flex;
    justify-content: center;
  }

  .section-text {
    width: 60%;
    margin: 10px;
    padding: 5px;
  }

  .section-image > img {
    height: 450px;
    width: auto;
    margin: 10px;
    padding: 10px;
  }

  /* Splash Screen */

  #home-screen {
    display: flex;
    flex-direction: column;
    justify-content: right;
    align-items: flex-start;
    height: auto;
    padding: 10px;
  }

  #de-logo {
    width: auto;
    height: 75vh;
  }

  #subheader {
    text-align: center;
  }

  #main-hover-menu {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .home-nav-link {
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  ol {
    display: flex;
    width: 40vw;
    flex-wrap: wrap;
    justify-content: space-between;
    list-style-type: none;
  }

  a:-webkit-any-link {
    text-decoration: none;
  }

  #home-footer {
    justify-content: right;
    width: 300px;
    padding-top: 10px;
  }

  #home-footer-text {
    font-size: 10px;
  }

  #home-footer-link {
    text-decoration: none;
  }

  #dropdown-nav {
    display: flex;
    text-align: left; 
    padding: 0px 10px;
    margin-top: 0px;
  }

  .me-auto.navbar-nav {
    display: flex;
    justify-content: space-evenly;
    width: 50rem;
    background: hsla(32,50%,90%,0.75);
  }

  .nav-option {
    background: none;
    font-family: QanelasSoftUltraLight;
  }

  .navbar {
    background: none;
  }

  .nav-option:hover {
    cursor: pointer;
  }


  #dropdown-link-container {
    width: 50rem;
  }

  #nav-parallax-layer {
    display: flex;
    margin: 20px;
    padding: 25px;
    justify-content: flex-start;
  }

  .floating-link {
    position: relative;
    height: 70%;
    object-fit: fill;
    -webkit-transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
    transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
  }

  .floating-link::after {
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 5px;
    opacity: 0;
    -webkit-transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
    transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
  }

  .floating-link:hover {
    cursor: pointer;
    -webkit-transform: scale(1.1, 1.1);
    transform: scale(1.1, 1.1);
  }

  .floating-link:hover::after {
    opacity: 1;
  }

  /* Portfolio Cards */

  .card-holder {
    padding: 5px;
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
    margin: 20px 10px;
  }

  .card-col {
    display: flex;
    justify-content: center;
  }

  .project-circle {
    position: relative;
    width: calc(14% + 10px);
    height: 70%;
    object-fit: fill;
    box-shadow: 0 1px 2px rgba(0,0,0,0.1);
    -webkit-transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
    transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
  }

  .project-circle::after {
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 5px;
    box-shadow: 0 5px 15px rgba(0,0,0,0.3);
    opacity: 0;
    -webkit-transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
    transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
  }

  .project-circle:hover {
    cursor: pointer;
    -webkit-transform: scale(1.1, 1.1);
    transform: scale(1.1, 1.1);
  }

  .project-circle:hover::after {
    opacity: 1;
  }

  .carousel {
    height: 50%;
  }

  .carousel-item {
    height: 100%;
    width: 100%;
  }

  .carousel-item.active, .carousel-item-next, .carousel-item-prev {
    display: flex !important;
    align-items: center;
    justify-content: center;
  }

  .project-image-carousel-image {
    display: flex;
    height: 60vh;
    object-fit: contain;
    width: auto;
}



  /* Wide */

  @media screen and (min-width: 961px) and (max-width: 2500px) {

    /* Basic */

      body, input, textarea, select {
        font-size: 17pt;
      }

      .row {
        align-items: center;
      }
      

      .section-image {
        align-items: center;
      }

    /* Header */

      .section-header {
        width: 300px;
      }

      .me-auto.navbar-nav {
        background: hsla(32,50%,90%,0.75);
      }

      .nav-option {
        background: none;
      }

      .navbar {
        background: none;
      }

    /* Image */

      img {
        display: flex;
        height: 300px;
        object-fit: cover;
        width: 100%;
      }

      .project-image-carousel-image {
          display: flex;
          height: 60vh;
          object-fit: contain;
          width: auto;
      }

  }

  /* Narrow */

  @media screen and (min-width: 961px) and (max-width: 1320px) {

    /* Basic */

      body, input, textarea, select {
        font-size: 16pt;
      }

      .container {
        padding: 0 2em 0 2em;
        display: flex;
        align-items: center;
        justify-content: center;
      }

    /* Header */

      header {
        width: 100%;
      }

      #responsive-navbar-nav {
        padding: 5px;
        margin-top: 5px;
        background: none;
      }

      .me-auto.navbar-nav {
        background: hsla(32,50%,90%,0.75);
      }

      .nav-option {
        background: none;
      }

      button {
        margin: 5px;
      }

      .section-header {
        display: flex;
        width: 20vw;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 10px;
      }

      #de-logo {
        width: auto;
        height: 50vh;
      }

      .section-text {
        width: 100%;
        padding: 8px;
        margin: 10px;
      }
    
    /* Image */

      .section-image {
        object-fit: scale-down;
        margin: 0px 0px 10px 0px;
        display: flex;
        justify-content: center;
      }

      .section-image > img {
        margin: 0px;
        height: 40vh;
        width: auto;
      }

      .project-circle {
        width: calc(25% + 10px);
      }

      .slide {
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .project-image-carousel-image {
        display: flex;
        height: 60vh;
        object-fit: contain;
        width: auto;
    }

  }

  /* Narrower */

  @media screen and (max-width: 1220px) {

    /* Basic */

      html, body {
        overflow-x: hidden;
      }

      body, input, textarea, select {
        font-size: 16pt;
      }

      header br {
        display: none;
      }

      .container {
        padding: 0 2em 0 2em;
      }

      .section-content {
        justify-content: center;
        align-content: center;
        padding: 10px;
      }

    /* Header */

      nav ul li a {
        padding: 0.5em 1.25em 0.5em 1.25em;
      }

      #responsive-navbar-nav {
        padding: 5px;
        margin-top: 5px;
      }

      button {
        margin: 5px;
        padding: 2px;
      }

      .navbar {
        flex-wrap: nowrap !important;
        background: none;
      } 

      #de-logo {
        width: auto;
        height: 33vh;
      }

      .container {
        display: flex;
        align-content: center;
        justify-content: center;
      }


      /* Section */

      .section-header {
        display: flex;
        width: 28vw;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 10px;
      }

      .section-text {
        width: 100%;
        margin: 10px;
      }

      .nav-mobile {
        display: flex;
        flex-wrap: nowrap;
      }

      .section-image {
        object-fit: scale-down;
        margin: 0px 0px 10px 0px;
        display: flex;
        justify-content: center;
      }

      .section-image > img {
        margin: 0px;
        height: 30vh;
        width: auto;
      }

      .project-circle {
        width: calc(25% + 10px);
      }

      .slide {
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .project-image-carousel-image {
        display: flex;
        height: 60vh;
        object-fit: contain;
        width: 100%;
    }


  }

  /* Mobile */

  @media screen and (max-width: 736px) {

    /* Basic */

      body, input, textarea, select {
        font-size: 14pt;
      }

      h2 {
        font-size: 1.5em;
        letter-spacing: 0;
        font-weight: 300;
      }

      .container {
        padding: 0 15px 0 15px;
      }

      .layer {
        display: flex;
        flex-direction: column;
      }

      #responsive-navbar-nav {
        padding: 5px;
        margin-top: 5px;
        background: none;
      }

      .me-auto.navbar-nav {
        background: hsla(32,50%,90%,0.75);
      }

      .nav-option {
        background: none;
      }

      .navbar {
        flex-wrap: nowrap !important;
        background: none;
      } 

      button {
        margin: 5px;
        padding: 2px;
      }

      /* Section */

      .section-header {
        display: flex;
        width: 50vw;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 10px;
      }

      .section-text {
        width: 100%;
        padding: 8px;
        margin: 10px;
      }

      .section-image {
        object-fit: scale-down;
        margin: 0px 0px 10px 0px;
      }

      .section-image > img {
        margin: 0px;
        height: 25vh;
        width: auto;
      }

      .project-circle {
        width: calc(25% + 10px);
      }

      .slide {
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .project-image-carousel-image {
        display: flex;
        height: 60vh;
        object-fit: contain;
        width: 100%;
        padding: 5%;
    }


  }

  @media screen and (max-width: 576px) {

    /* Basic */

      body, input, textarea, select {
        font-size: 12pt;
      }

      h2 {
        font-size: 1em;
        letter-spacing: 0;
        font-weight: 300;
      }

      .container {
        padding: 0 15px 0 15px;
      }

    /* Splash Screen Nav */

      #responsive-navbar-nav {
        padding: 5px;
        margin: 5px;
        background: none !important;
      }

      .navbar {
        background: none;
      }

      .form-label {
        display: none;
      }

      button {
        margin: 5px;
        padding: 2px;
      }

      .button.navbar-toggler {
        margin: 5px;
      }

    /* Section */

    .section-header {
      display: flex;
      width: 50vw;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 10px;
      background: none;
    }

    .section-box {
      display: flex;
    }

    .section-text {
      width: 100%;
      padding: 8px;
      margin: 10px;
    }
    
    #email-form > h3{
      font-size: 1.2em;
    }

    .project-circle {
      width: calc(25% + 10px);
    }

    .slide {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .project-image-carousel-image {
      display: flex;
      height: 60vh;
      object-fit: contain;
      width: 100%;
  }

  }

  @media screen and (max-width: 360px)  {

    /* Basic */

      body, input, textarea, select {
        font-size: 12pt !important;
      }

      h1 {
        font-size: 1em !important;
      }

      h2 {
        font-size: 0.9em !important;
        letter-spacing: 0;
        font-weight: 300;
      }

      h3 {
        font-size: 0.8em !important;
      }

      .form-label {
        display: none !important;
      }

      input {
        font-size: 0.8em !important;
      }

      button {
        font-size: 0.8em !important;
      }

      .container {
        padding: 0 15px 0 15px;
      }

    /* Splash Screen Nav */

      #responsive-navbar-nav {
        padding: 5px;
        margin: 5px;
        background: none;
      }

      .navbar {
        background: none;
      }

      .me-auto.navbar-nav {
        background: hsla(32,50%,90%,0.75);
      }

      .nav-option {
        background: none;
      }

      button {
        margin: 5px;
        padding: 2px;
      }

      .button.navbar-toggler {
        margin: 5px;
      }

    /* Section */

    .section-header {
      display: flex;
      width: 36vw;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 10px;
      background: none;
    }

    .section-box {
      display: flex;
    }

    .section-text {
      width: 100%;
      padding: 8px;
      margin: 10px;
    }

    .project-circle {
      width: calc(25% + 10px);
    }

    .slide {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .project-image-carousel-image {
      display: flex;
      height: 60vh;
      object-fit: contain;
      width: 100%;
  }

    
  }

  @media screen and (max-width: 280px)  {
    p {
      font-size: 14px;
    }

    .section-header {
      display: flex;
      width: 46vw;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 10px;
      background: none;
    }

    .section-text {
      width: 100%;
      padding: 8px;
      margin: 10px;
    }

  }
